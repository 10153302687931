import React, {StrictMode} from 'react';
import Enrutador from './routes/Enrutador'
import { createRoot } from 'react-dom/client';
const root = createRoot(document.getElementById('root'));
root.render(
    <StrictMode>
      <Enrutador/>
    </StrictMode>
 
);


import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import '../css/Login.css';
import Cookies from 'universal-cookie';
import { Redirect } from 'react-router-dom';

const cookies = new Cookies();

class CambiarClave extends Component {
    state = {
        form: {
            currentPassword: '',
            password: '',
            confirmPassword: ''
        },
        isConfirmed: false,
        redirectToLogin: false
    }

    componentDidMount() {
        const id = cookies.get('id');
        const idprofesor = cookies.get('idprofesor');
        if (id && !this.state.form.id) {
            this.setState({
                form: {
                    ...this.state.form,
                    id: id
                }
            });
        }
    }

    handleChange = async e => {
        await this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });

        const { currentPassword, password, confirmPassword } = this.state.form;
        if (currentPassword && password === confirmPassword) {
            this.setState({ isConfirmed: true });
        } else {
            this.setState({ isConfirmed: false });
        }
    }

    cambiarClave = async () => {
        const { form, isConfirmed } = this.state;
        const { id,  password } = form;

        if (!isConfirmed) {
            alert('Por favor, confirma la nueva contraseña antes de cambiarla');
            return;
        }

        if (!password) {
            alert('Por favor, completa todos los campos');
            return;
        }

        const token = cookies.get('token');

        if (!token) {
            alert('No se encontró el token. Inicia sesión nuevamente.');
            return;
        }
        const serverApi = cookies.get('server');

        try {
            const response = await axios.post(
                serverApi+"usuariocambiarclave",
                {
                    id: id,
                    clave: password
                   
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            if (response.data.success) {
                alert('Error al cambiar la contraseña');
            } else {
                alert('Contraseña cambiada exitosamente');
                this.setState({ redirectToLogin: true });
            }
        } catch (error) {
            console.error('Error al procesar la respuesta del servidor:', error);

            if (error.response) {
                console.log('Código de estado:', error.response.status);
                console.log('Respuesta del servidor:', error.response.data);
                alert(`Error del servidor: ${error.response.data.message || 'Error desconocido'}`);
            } else {
                alert('Error al procesar la respuesta del servidor');
            }
        }
    }

    render() {
        if (this.state.redirectToLogin) {
            // return <Redirect to="/" />;
        }
        return (
            <div className="containerPrincipall">
                <div className="containerSecundarioo">
                    <div className="form-group">
                        <label>Contraseña Actual: </label>
                        <br />
                        <input
                            type="password"
                            className="form-control"
                            name="currentPassword"
                            onChange={this.handleChange}
                        />
                        <br />
                        <label>Nueva Contraseña: </label>
                        <br />
                        <input
                            type="password"
                            className="form-control"
                            name="password"
                            onChange={this.handleChange}
                        />
                        <br />
                        <label>Confirmar Nueva Contraseña: </label>
                        <br />
                        <input
                            type="password"
                            className="form-control"
                            name="confirmPassword"
                            onChange={this.handleChange}
                        />
                        <br />
                        <button className="btn btn-primary" onClick={() => this.cambiarClave()}>Cambiar Contraseña</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default CambiarClave;

import React, { Component,setState, useEffect, useState } from 'react';
import axios from 'axios'; 
import locale from 'antd/es/date-picker/locale/es_ES'
import Cookies from 'universal-cookie';
import { Button, Modal,Result,
  DatePicker,
  Form,
  Input,InputNumber,Select,Space,Table, Spin,message,Flex,Layout, Breadcrumb,theme,Popconfirm } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const cookies = new Cookies();
const { Option } = Select;
const { Header, Content, Footer } = Layout;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};



const AsignaturasTareas = (props)=>{
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
    const [form1] = Form.useForm();
    const[inCase,setInCase]= useState(true);
    const [loading,setLoading] = useState(true);
    const [error,seterror] = useState(null)
    const [messageApi, contextHolder] = message.useMessage();
    const [tareaPrint,setTareaPrint]= useState([]);
    const [isModalOpen,setisModalOpen]=useState(false);
    const [isLoadingInsert,setLoadingInsert] = useState(false);
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [date,setdate] = useState(null);
    const [periodo,setPeriodo]=useState(1);
    const [saved,setSaved]= useState(true);
    const [ActiAx,setActiAx]=useState(0);
    const [ActiEx,setActiEx]=useState(0);
    const [VYAx,setVYAx]=useState(0);
    const [ExpAdhx,setExpAdhx]=useState(0);
    const [Evx,setEv]=useState(0);
    const [allHomeworks,setAllHomework]=useState([]);
    const [isModalOpen2,setisModalOpen2]=useState(false);
    const [activeTarea,setActivetarea] = useState(null);
    const [total,setTotal]= useState(0);
    const [AsignmentName ,setAsignmentName]= useState("");
    const [inputBlocked,setInputBlocked]=useState(false);

    useEffect(()=>{
        if (cookies.get('idprofesor')==null || !cookies.get('desc')==null) {
            // Redirigir si no hay un id de profesor en las cookies
            
            window.location.href='/';
          }else{
            
          const hwn = cookies.get('desc');
          setAsignmentName(hwn);
        
           cargarTareas();
           
           
          }

    },[saved,periodo,inCase]);


  
    
    const info = (value) => {
      messageApi.info(value);
    };

    const seeParcial = ()=>{
      
    }


      const showModal = () => {
        setisModalOpen(true);
      };
  
      const handleCancel = () => {
        setisModalOpen(false);
        form.resetFields();
        
      };

      const changeParcial = (values)=>{
        setPeriodo(values);
      }

      const checkParcial = async()=>{

      }

      const onFinish=async (values)=>{
        
        setisModalOpen(false);
        const CodA = cookies.get('codAsignatura');
        const CodS = cookies.get('codSeccion');
        const serverApi = cookies.get('server');
        const token = cookies.get('token');
        var _descripcion = values.descripcion;

        var _fechaCierre = date; 
       
        var _valor = values.valor;
        var _tipo = values.tipo;

        try{
        const response = await axios.post(
          serverApi+"RegistrarTarea",
          {
            CodA: CodA,
            CodS: CodS,
            parcial: parseInt(periodo),
            descripcion: _descripcion,
            est: true,
            _fechaEntrega: _fechaCierre,
            _fechaCierre: _fechaCierre,
            _tipo: _tipo,
            _valor: parseFloat(_valor),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
      
        )
        if(response.data === "Tarea registrada con éxito."){
          setisModalOpen(false);
          setSaved(!(saved));
          info("Tarea registrada con éxito");
        }else{
          info("Hubo un error");
        }

      }catch(e){
          console.log(e);
        }
      }

      const ToSigace = async()=>{
       setLoadingInsert(true);
        const codasignatura = cookies.get('codAsignatura');
        const codseccion = cookies.get('codSeccion');
        const serverApi = cookies.get('server');
        const token = cookies.get('token');
        
        const config = { headers: { Authorization: `Bearer ${token}` } };
        try {
          const response = await axios.post(serverApi + "CalificacionesAsignatura", {codseccion,codasignatura,periodo }, config);
           
          
          
        } catch (error) {
          console.error(error);
          error="Upss... Ocurrio un Errror al Ingresar al Borrar la Tarea";
          setLoadingInsert(false);
         
          
        }
        setLoadingInsert(false);
        info("Sigace Registrado");
      }

      const onChange = (date, dateString) => {
        setdate(new Date(dateString));
      };

      const cargarTareas = () => {




        var allData = [];
        setLoading(true);
        setLoadingInsert(true);
        const Idprofesor = cookies.get('idprofesor');
        const codasignatura = cookies.get('codAsignatura');
        const codseccion = cookies.get('codSeccion');
        const token = cookies.get('token');
        const serverApi = cookies.get('server');
        var counto=0;
        var ActiA=0;
        var ActiE=0;
        var VYA=0;
        var ExpAdh=0;
        var Ev=0;
       
        const parcial = periodo;
    
        const config = { headers: { Authorization: `Bearer ${token}` } };
        //Lllamado a api
        try {
           axios.post(serverApi + "ObtenerAlumnosAsignatura", 
          { codasignatura, codseccion}, config).then(response=>{
            
            if(response.data){
             response.data.map(async (element,index)=>{
              const Identidad = element.idalumno;
          const CodA = codasignatura;
          const CodS = codseccion;
          // console.log(CodA);
          // console.log(CodS);
          // console.log(element)
;        var tmp = {"nombre":element.nombre_alumno,"identidad":element.idalumno};
              //console.log(element.idalumno);
           axios.post(serverApi + "ObtenerTareasAlumno", 
          { Identidad ,CodA,CodS,parcial}, config).then(
            clasesAlumno =>{
              var total=0;
              var totalSum=0;
               tmp.clases=clasesAlumno.data;
               //console.log(clasesAlumno.data);
              
              clasesAlumno.data.map((asign,index)=>{
                total += asign.valorObtenido;
              })

              clasesAlumno.data.map((asign,index)=>{
                totalSum += asign.valor;
              })
              tmp.totalV=totalSum;
              tmp.total=total;
               if(counto===0){
                for(var i=0;i<clasesAlumno.data.length;i++){
                 
                    if(clasesAlumno.data[i].tipo==="Actividades-Aula"){ 
                      
                          ActiA=ActiA+1;
                          
                    }
                    if(clasesAlumno.data[i].tipo==="Actividades-Extra-Aula"){
                                ActiE++;
                      }
                          if(clasesAlumno.data[i].tipo==="Valores y actitudes"){
                            VYA++;
                      }
                      if(clasesAlumno.data[i].tipo==="Expresiones ADH"){
                        ExpAdh++;
                  }
                  if(clasesAlumno.data[i].tipo==="Evaluaciones"){
                    Ev++;
              }

                    
                }
                counto=1;
               }
            }
          );

            // console.log(tmp);
            allData.push(tmp);
            
            
            // console.log(ActiE);
            // console.log(VYA);
            // console.log(ExpAdh);
            // console.log(Ev);
          
            })            
          }

          });
          
          setTimeout(() => {
          
          setTareaPrint(allData); 
          //console.log(allData);
          setActiAx(ActiA); 
           setActiAx(ActiA);
            setActiEx(ActiE);
            setExpAdhx(ExpAdh);
            setEv(Ev);
            setVYAx(VYA); 
            setLoadingInsert(false);      
                
          }, "2000");
         
          // console.log(response);
       
          setLoading(false);
          
        } catch (error) {
          console.error(error);
          setLoading(false);
          
          
        }


       

      };


      const savingValue = async(values)=>{
        var error="";
        setLoadingInsert(true);
        const Idprofesor = cookies.get('idprofesor');
        const token = cookies.get('token');
        const serverApi = cookies.get('server');
    
        const config = { headers: { Authorization: `Bearer ${token}` } };

        
        tareaPrint.map((element,index)=>{
          //console.log(element);
          element.clases.map(async (value,index)=>{
            const Id =value.idasing;
            const Valor =values[value.idasing];
            
          try {
          const response = await axios.post(serverApi + "ActualizarNotas", { Id,Valor }, config);
           
          
          
        } catch (error) {
          console.error(error);
          error="Upss... Ocurrio un Errror al Ingresar las Notas";
          setLoading(false);
         
          
        }
          })
        });
        
        setTimeout(() => {
          
          
           
          
            setLoadingInsert(false);      
                
          }, "1000");

        if(error===""){
          info("Guardado Exitosamente");
          setInCase(!(inCase));
          setTimeout(() => {
          
          
           
          
           
              
          }, "1000");
          
        }else{
          info(error);
        }


        
        
      }
     
      const deleteHomework = async(Homework)=>{
        var error="";
        
        const Id= Homework;
        setLoadingInsert(true);

        
        const token = cookies.get('token');
        const serverApi = cookies.get('server');
    
        const config = { headers: { Authorization: `Bearer ${token}` } };
        try {
          const response = await axios.post(serverApi + "BorrarTarea", { Id }, config);
           
          
          
        } catch (error) {
          console.error(error);
          error="Upss... Ocurrio un Errror al Ingresar al Borrar la Tarea";
          setLoading(false);
         
          
        }

        setLoadingInsert(false);
        setInCase(inCase=>!inCase);

        if(error===""){
          info("Borrado Exitosamente");
        }else{
          info(error);
        }

      }

      const confirm = (e) => {
        deleteHomework(e);
      };
      const cancel = (e) => {
        
      };

      
      

      


      


    
        return (
          tareaPrint==undefined?<h1><center>Asignaturas</center></h1>:
          <div style={{textAlign:"center"}}>
            <Layout>
    <Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          textAlign:'center',
        }}
      >
        
       <h1 style={{width:"100%",color:'white'}}>Clase: {AsignmentName}</h1>
      </Header>
      <Content
        style={{
          padding: '0 48px',
          height:'100%',
        }}
      >
        <Breadcrumb
          style={{
            margin: '16px 0',
          }}
        >
          <Breadcrumb.Item>Maestro</Breadcrumb.Item>
          <Breadcrumb.Item>Asignaturas</Breadcrumb.Item>
          <Breadcrumb.Item>Tareas</Breadcrumb.Item>
         
        </Breadcrumb>
        
        <div
          style={{
           padding:"1%",
            minHeight: '100%',
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
             {contextHolder}
            
            {error && <p>Error: {error.message}</p>}
            <Spin spinning={isLoadingInsert}>
            {loading ? <p><em>Loading...</em></p> : 
            <Form form={form1} onFinish={savingValue} style={{paddingTop:"1%"}}>
              <Form.Item>  
              <Flex gap="small" wrap="wrap">
             <h4>Parcial:</h4>
            <Select style={{width:"100px"}}
            placeholder="Seleccion un parcial"
            onChange={changeParcial} defaultValue={"Primero"}>
                    <Option value="1">Primero</Option>
                    <Option value="2">Segundo</Option>
                    <Option value="3">Tercero</Option>
                    <Option value="4">Cuarto</Option>
                  </Select>
                  <Button  type="primary" onClick={showModal}>Agregar Tarea</Button>
                <Button type="primary" htmlType="submit">
                  Guardar
                </Button>
                <Button type="primary" onClick={ToSigace}>
                  Guardar Sigace
                </Button>
                <Button type="primary" >
                  Guardar Sace
                </Button>
                </Flex>

                </Form.Item>

               <div>
              <div style={{overflowX:'auto'}}>
            <table 
            style={{
              width: '100%',
              

              
            borderCollapse: 'collapse'
              }}
            >
               <thead style={{
            width: "100%",
            }} >
           
              <tr style={{
            width: '100%',
            }}>
               <th style={{background: '#f4f4f4',
            position: '-webkit-sticky', /* For Safari */
            position: 'sticky',
            top: 0,
            }}></th>
                {ActiAx>0?<th colSpan={ActiAx} style={{background: '#f4f4f4',
            position: '-webkit-sticky', /* For Safari */
            position: 'sticky',
            top: 0,
            }} >Actividades-Aula</th>:<></>}
                {ActiEx>0?<th colSpan={ActiEx} style={{background: '#f4f4f4',
            position: '-webkit-sticky', /* For Safari */
            position: 'sticky',
            top: 0,
            }}>Actividades-Extra-Aula</th>:<></>}
                {Evx>0?<th colSpan={Evx} style={{background: '#f4f4f4',
            position: '-webkit-sticky', /* For Safari */
            position: 'sticky',
            top: 0,
            
            }}>Evaluaciones</th>:<></>}
                {ExpAdhx>0?<th colSpan={ExpAdhx} style={{background: '#f4f4f4',
            position: '-webkit-sticky', /* For Safari */
            position: 'sticky',
            top: 0,
            }}>Expresiones ADH</th>:<></>}
                {VYAx>0?<th colSpan={VYAx} style={{background: '#f4f4f4',
            position: '-webkit-sticky', /* For Safari */
            position: 'sticky',
            top: 0,
            }}>Valores y Actitudes</th>:<></>}
                <th style={{background: '#f4f4f4',
            position: '-webkit-sticky', /* For Safari */
            position: 'sticky',
            top: 0,
            }}>Total</th>

              </tr>
              
               <tr style={{
            width: '100%',
            }}>
                <th style={{background: '#f4f4f4',
            position: '-webkit-sticky', /* For Safari */
            position: 'sticky',
            top: 0,
            
            }}>Nombre</th>
                {tareaPrint[0]==undefined?<></>:tareaPrint[0].clases.map((asignatura, index) =>
                
               <th style={{background: '#f4f4f4',
                position: '-webkit-sticky', /* For Safari */
                position: 'sticky',
                top: 0,
                
                fontSize:'12px'
                } } >
                  
                  {asignatura.descripcion + " "+ asignatura.valor+"-pts"}

                    <Popconfirm
                      title="Borrar Tarea"
                      description="Desea Borrar la Siguiente Tarea?"
                      onConfirm={()=>{
                        
                        deleteHomework(asignatura.id_tarea);
                      }}
                      onCancel={cancel}
                      okText="Si"
                      cancelText="No"
                    >
            <Button style={{float:'right', height:20,width:12, justifyContent:'center'}} type="primary" shape="circle" danger>X</Button>
          </Popconfirm>
                  <Button style={{float:'right'}}
                  onClick={()=>{
                    
                    cookies.set('idTarea', asignatura.id_tarea, { path: "/tareaedit" });
                    window.location.href='/tareaedit';

                    // window.open('/tareaedit','_blank');
                  }}
                  type="primary" shape="circle" danger>
                    <EditOutlined />
                  </Button> 
                  
                </th>
              )}
               <th style={{background: '#f4f4f4',
            position: '-webkit-sticky', /* For Safari */
            position: 'sticky',
            top: 0,
            zIndex: '1',
            padding: '8px 12px',
            border: '1px solid #ddd',
            textAlign: 'left'}}>{tareaPrint[0]==undefined?<></>:tareaPrint[0].totalV}</th>
              </tr>
            </thead>
            <tbody style={{ 
            height: '400px', /* Adjust height as needed */
            overflowY: 'auto' }}>
              
              {tareaPrint==undefined?<></>:tareaPrint.map((asignatura, index) =>
                <tr style={{display: 'table',
                  width: '100%',
                  tablelayout: 'fixed',
                  
                  }}>
                  <td>{asignatura.nombre}</td>
                  
                  {tareaPrint[index].clases.map((asign,index)=>
                    
                  <td> <Form.Item 
                  name={asign.idasing}
                  initialValue={asign.valorObtenido}
                  style={{height:"0px"}}
                  rules={[
                    {
                      
                      message: 'valor no aceptado muy alto o alfanumerico',
                      validator:(rule,value)=>{
                        if(value == null || value ==undefined || isNaN(value)){
                          return Promise.reject("valor no aceptado muy alto o alfanumerico");
                        } else if (value>asign.valor){
                          return Promise.reject("Valor muy alto");
                        }else{
                          return Promise.resolve("");
                        }
                        
                      }
                    },
                  ]}
                >
                   <InputNumber
                    
                    // disabled={true}
                    style={{
                      width: '100%',
                    }}
                    />
                   </Form.Item></td>)
                  
                   }
                   <td>
                    {asignatura.total}
                   </td>
                </tr>
              )} 
            </tbody>
          </table>
          </div>
          </div>
          </Form>}

          

          <Modal title="Agregar Tarea" open={isModalOpen}  footer={null} closable={false}>
              <Spin spinning={isLoadingInsert}>
         
             
                      <Form
                        {...formItemLayout}
                        style={{
                        maxWidth: 600,
                        }}
                        form={form}
                        name="submit"
                        onFinish={onFinish}

                      >
                        <Form.Item
                          name="descripcion"
                          label="Descripcion"
                          
                          rules={[
                            {
                              required: true,
                              message: 'Ingrese descripcion de tarea',
                              
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item >

                        <Form.Item name="cierreTarea" label="Fecha de Ciere" rules={[
                            {
                              required: true,
                              message: 'Seleccione un dia',
                            },
                          ]}>
                        <DatePicker locale={locale} onChange={onChange} />
                          </Form.Item>


                        




                        <Form.Item
                          name="valor"
                          label="Valor"
                          rules={[
                            {
                              required: true,
                              message: 'Ingrese un valor',
                            },
                          ]}
                        >
                          <InputNumber

                            style={{
                              width: '100%',
                            }}
                          />
                        </Form.Item>

                      
                        <Form.Item
                          name="tipo"
                          label="Tipo"
                          rules={[
                            {
                              required: true,
                              message: 'Seleccione un tipo!',
                            },
                          ]}
                            >
                          <Select placeholder="Seleccion el tipo de tarea">
                            <Option value="Actividades-Aula">Actividades-Aula</Option>
                            <Option value="Actividades-Extra-Aula">Actividades-Extra-Aula</Option>
                            <Option value="Valores y actitudes">Valores y actitudes</Option>
                            <Option value="Expresiones ADH">Expresiones ADH</Option>
                            <Option value="Evaluaciones">Evaluaciones</Option>

                          </Select>
                        
                        </Form.Item>       
                        <Form.Item  {...tailFormItemLayout}>  
                        <Button type="primary" htmlType="submit">
                          Agregar
                        </Button>
                          
                        <Button style={{marginLeft:50}} onClick={handleCancel} htmlType="submit">
                          Cancelar
                        </Button>
                      </Form.Item>
                            
                    </Form>
                    </Spin>
              </Modal>

      </Spin>
        
      </div>
      </Content>
      
    </Layout>
          </div>

        );

      

}

export default AsignaturasTareas;
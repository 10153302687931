import React from 'react';
import {BrowserRouter,Route,Routes} from 'react-router-dom';
import Menu from '../pages/Menu';
import Historialeconomico from '../pages/historialeconomico';
import Historialacademico from '../pages/historialacademico' ;
import Historialdisciplinario from '../pages/historialdisciplinario' ;
import Listaencargado from '../pages/listaencargado' ;
import LoginEncargado from '../pages/LoginEncargado';
import Encargado from '../pages/encargado';
import cambiarclave from '../pages/cambiarclave';
import Tarea from '../pages/tarea';
import Obtenertarea from '../pages/obtenertarea';
import Obtenertareaalumno from '../pages/obtenertareaalumno';
import Asignaturaalumno from '../pages/asignaturaalumno';
import Maestro from '../pages/maestro';
import Actualizarvalor from '../pages/actualizarvalor';
import AsignaturasMaestroFunc from '../pages/asignaturasmaestrofunc';
import AsignaturasTareas from '../pages/asignaturasTarea';
import LogInView from '../pages/loginmaterial';
import Horario from '../pages/horario';
import TareasEdit from '../pages/editarTareas';
import SelectRol from '../pages/seleccionarrolantd';
import CambiarClave from '../pages/cambiarclave';
import MaestoMenu from '../pages/maestroFunc';
import MenuAlumno from '../pages/alumnofunc';



function Enrutador() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<LogInView/>}/>
        <Route exact path="/menu" component={Menu}/>
        <Route exact path="/alumno" element={<MenuAlumno/>}/> 
        <Route exact path="/encargado" component={<Encargado/>}/>
        <Route exact path="/historialeconomico" element={<Historialeconomico/>}/>
        <Route exact path="/historialacademico" element={<Historialacademico/>}/>
        <Route exact path="/historialdisciplinario" element={<Historialdisciplinario/>}/>
        <Route exact path="/horario" element={<Horario/>}/>
        <Route exact path="/listaencargado" element={<Listaencargado/>}/>
        <Route exact path="/LoginEncargado" element={LoginEncargado}/>
        <Route exact path="/cambiarclave" element={<CambiarClave/>}/>
        <Route exact path="/tarea" element={<Tarea/>}/>
        <Route exact path="/obtenertarea" element={<Obtenertarea/>}/>
        <Route exact path="/obtenertareaalumno" element={<Obtenertareaalumno/>}/>
        <Route exact path="/maestro" element={<MaestoMenu/>}/>
        <Route exact path="/actualizarvalor" element={<Actualizarvalor/>}/>
        <Route exact path="/asignaturaalumno" element={<Asignaturaalumno/>}/>
        <Route exact path="/SeleccionarRol" element={<SelectRol/>}/>
        <Route exact path="/asignaturasmaestro" element={<AsignaturasMaestroFunc/>}/>
        <Route exact path="/asignaturastarea" element={<AsignaturasTareas/>}/>
        <Route exact path="/tareaedit" element={<TareasEdit/>}/>



      </Routes>
    </BrowserRouter>
  );
}

export default Enrutador;

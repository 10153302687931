import React, { Component } from 'react';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class Encargado extends Component {
    cerrarSesion=()=>{
        cookies.remove('id', {path: "/"}); 
        window.location.href='./';
    }

    componentDidMount() {
        if(!cookies.get('id')){
            window.location.href="./";
        }
    }
 mostrarHEconomico () {

        window.location.href= '/historialeconomico'; // Redirige a la ruta especificada
    }
    
    render() { 
        return (
            <div>
                Pantalla de encargados

                <br />
                <button onClick={()=>this.cerrarSesion()}>Cerrar Sesión</button>
            </div>
        );
    }
}

export default Encargado;
import React, { Component } from 'react';
import axios from 'axios';
//import '../css/obtenertarea.css';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class ObtenerTareaAlumno extends Component {
  state = {
    form: {
      Identidad: ""
    },
    tareaData: [],
    error: null,
  };

  componentDidMount() {
    const Identidad = cookies.get('id');

    if (Identidad && !this.state.form.Identidad) {
      this.setState({
        form: {
          ...this.state.form,
          Identidad: Identidad
        }
      });
    }
  }

  obtenerTarea = async () => {
    try {
      
      const token = cookies.get('token');
      const { Identidad } = this.state.form;
      const serverApi = cookies.get('server');

      const response = await axios.post(
        serverApi+"obtenerTareasAlumno",
        {
          Identidad: Identidad
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log('Respuesta:', response.data);

      this.setState({
        tareaData: response.data || [],
        error: null,
      });
    } catch (error) {
      console.error('Error:', error);

      this.setState({
        tareaData: [],
        error: 'Error al obtener la tarea. Verifica los datos ingresados.',
      });
    }
  };

  render() {
    const { tareaData, error } = this.state;
  
    return (
      <div className="containerPrincipaal">
        <div className="containersecundariiio">
          <div className="form-group">
            <div className="row">
              <div className="col-md-12">
                <button className="btn btn-primary" onClick={this.obtenerTarea}>
                  Obtener Tarea
                </button>
              </div>
            </div>
  
            {tareaData.length > 0 && (
              <div className="row mt-3">
                <div className="col-md-12">
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ display: 'none' }}>Código Asignatura</th>
                        <th style={{ display: 'none' }}>Código Sección</th>
                        <th>Asignatura</th>
                        <th>Descripción</th>
                        <th  style={{ display: 'none' }}>Estado</th>
                        <th>Fecha Cierre</th>
                        <th>Fecha Entrega</th>
                        <th>Parcial</th>
                        <th  style={{ display: 'none' }}>Tipo</th>
                        <th>Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tareaData.map((tarea, index) => (
                        <tr key={index}>
                          <td style={{ display: 'none' }}>{tarea.codasignatura}</td>
                          <td style={{ display: 'none' }}>{tarea.codseccion}</td>
                          <td>{tarea.nombre}</td>
                          <td>{tarea.descripcion}</td>
                          <td  style={{ display: 'none' }}>{tarea.estado ? 'Activo' : 'Inactivo'}</td>
                          <td>{tarea.fechaCierre}</td>
                          <td>{tarea.fechaEntrega}</td>
                          <td>{tarea.parcial}</td>
                          <td  style={{ display: 'none' }}>{tarea.tipo}</td>
                          <td>{tarea.valor}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
  
            {error && (
              <div className="row mt-3">
                <div className="col-md-12">
                  <p className="text-danger">{error}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ObtenerTareaAlumno;
import React, { Component } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class actualizarvalor extends Component {
  state = {
    id: '',
    valor: '',
   
    task: null,
    error: null,
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.valor
    });
  }

  buscarYActualizarValor = async () => {
    const token = cookies.get('token');
  const { id, valor } = this.state;

  try {
    // Realizar una solicitud para actualizar el valor de la tarea
    const response = await axios.post(
      'https://castinnovation.somee.com/api/ActualizarNotas',
      {
        Id: id, // Ajusta el nombre del campo según lo que espera la API
        valor: parseFloat(valor),
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    // Opcionalmente, puedes manejar la lógica de éxito aquí
    console.log('Response:', response.data);
    alert('Valor actualizado correctamente!');
  
  } catch (error) {
    console.error('Error:', error.response ? error.response.data : error.message);
  
    // Acceder a detalles específicos de error si están disponibles
    if (error.response && error.response.data && error.response.data.errors) {
      console.log('Detalles del error de validación:', error.response.data.errors);
    }
  
    alert('Error al actualizar el valor. Por favor, inténtelo nuevamente.');
  }
};

  render() {
    return (
      <div className="containerPrincipalActualizar">
        <div className="containerSecundario">
          <div className="form-group">
            <div className="row">
              <div className="col-md-6">
                <label>ID de la Tarea:</label>
                <input
                  type="text"
                  className="form-control2"
                  name="id"
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <label>Nuevo Valor:</label>
                <input
                  type="text"
                  className="form-control2"
                  name="valor"
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <button className="btn btn-primary" onClick={this.buscarYActualizarValor}>
                  Buscar y Actualizar Valor
                </button>
              </div>
            </div>

            {this.state.task && (
            <>
              <div className="row">
                <div className="col-md-12">
                  <label>Tarea Actual:</label>
                  {/* Mostrar detalles de la tarea aquí */}
                  <p>Descripción: {this.state.task.descripcion}</p>
                  <p>Valor Actual: {this.state.task.valor}</p>
                </div>
              </div>
            </>
          )}

          {this.state.error && (
            <div className="row">
              <div className="col-md-12">
                <p style={{ color: 'red' }}>{this.state.error}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
}

export default actualizarvalor;

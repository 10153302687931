import  { useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import SchoolIcon from '@mui/icons-material/School';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const { Header, Content, Footer } = Layout;




export default function SelectRol() {
  
      const handleEncargadoClick = () => {
        window.location.href = "./listaencargado";
    };

    const handleProfesorClick = () => {
        window.location.href = "./maestro";
    };
   
    const [nombre,setnombre]=useState("");

    useEffect(() => {
      setnombre(cookies.get('nombre'));
      
    }, []);
  return (
    
    <div style={{textAlign:"center"}}>
    <Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          textAlign:'center',
        }}
      >
        
       <h1 style={{width:"100%",color:'white'}}>Seleccionar Rol: {nombre}</h1>
      </Header>
    <Paper sx={{ width: 320, maxWidth: '100%', position: "absolute",
    width:"400px",
    
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "10px" }}>
      
      <MenuList>
        <MenuItem onClick={handleProfesorClick}>
          <ListItemIcon>
            <SchoolIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Profesor</ListItemText>
          <Typography variant="body2" color="text.secondary">
            ⌘
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleEncargadoClick}>
          <ListItemIcon>
            <EscalatorWarningIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Encargado</ListItemText>
          <Typography variant="body2" color="text.secondary">
            ⌘
          </Typography>
        </MenuItem>
        </MenuList>
    </Paper>
    </div>);
}

import React, { Component,setState, useEffect, useState } from 'react';
import axios from 'axios';
import locale from 'antd/es/date-picker/locale/es_ES'
import { Button, Modal,Result,
    DatePicker,
    Form,
    Input,InputNumber,Select,Space,Table, Spin,message} from 'antd';
import {useParams,useNavigate} from 'react-router-dom';
import Cookies from 'universal-cookie';
import dayjs from "dayjs";
import { Breadcrumb, Layout, Menu, theme } from 'antd';
const { Header, Content, Footer } = Layout;
const { Option } = Select;

const cookies = new Cookies();
const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 16,
      },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  
const TareasEdit = (props)=>{
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

    const [asignaturasData,setasignaturasData] = useState([]);
    const [saved,setSaved]= useState(true);
    const [loading,setLoading] = useState(true);
    const [codperA,setcodperA]=useState('2023');
    const [error,seterror] = useState(null);
    const [periodo,setPeriodo]=useState(1);
    const [isModalOpen,setisModalOpen]=useState(false);
    const [date,setdate] = useState(null);
    const [isLoadingInsert,setLoadingInsert] = useState(false);
    const [form] = Form.useForm();
    const [activeTarea,setActiveTarea] = useState(null);
    const [messageApi, contextHolder] = message.useMessage();
    const history= useNavigate();
    useEffect(()=>{
        if (!cookies.get('idprofesor')) {
            // Redirigir si no hay un id de profesor en las cookies
            history.push('/');
          }else{
           cargarAsignaturas();
          }

    },[]);


    const changeParcial = (values)=>{
        setPeriodo(values);
      }
      
      const handleCancel = () => {
        setisModalOpen(false);
        form.resetFields();
        
      };

      
      const onChange = (date, dateString) => {
        setdate(new Date(dateString));
      };

    const openModal =()=>{

    }
      
    const showModal = () => {
        setisModalOpen(true);
      };

      const cargarAsignaturas = async () => {
        
        const Idprofesor = cookies.get('idprofesor');
        const token = cookies.get('token');
        const serverApi = cookies.get('server');
        const id = cookies.get("idTarea");
        const parcial = periodo;
        
    
        const config = { headers: { Authorization: `Bearer ${token}` } };
        try {
          const response = await axios.post(serverApi + "ObtenerTareaEsp", { id}, config);
          
          
          setTimeout(() => {
          setActiveTarea(response.data[0]);
          setasignaturasData(response.data);
            const dia = dayjs(response.data[0].fechaCierre,"YYYY-MM-DD");
            setdate(dia);
          
          setLoading(false);
                  
            }, "1000");
         
        } catch (error) {
          console.error(error);
          setLoading(false);
          setasignaturasData([]);
          
        }
      };

      const onFinish=async (values)=>{
        setLoadingInsert(true);
        setisModalOpen(false);
        const CodA = cookies.get('codAsignatura');
        const CodS = cookies.get('codSeccion');
        const serverApi = cookies.get('server');
        const token = cookies.get('token');
        const id = cookies.get("idTarea");
        var _descripcion = values.descripcion;

        var _fechaCierre = date; 
       
        var _valor = values.valor;
        var _tipo = values.tipo;

        try{
        const response = await axios.post(
          serverApi+"ActualizarTarea",
          {
            id:id,
            CodA: CodA,
            CodS: CodS,
            parcial: parseInt(periodo),
            descripcion: _descripcion,
            est: true,
            _fechaEntrega: _fechaCierre,
            _fechaCierre: _fechaCierre,
            _tipo: _tipo,
            _valor: parseFloat(_valor),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
      
        )
        if(response.data === "Tarea Actualizada Exitosamente"){
          setisModalOpen(false);
          setSaved(!(saved));
          setLoadingInsert(false);
          info("Tarea Actualizada Exitosamente");
          history(-1);
         
        }else{
          setLoadingInsert(false);
          info("Hubo un error");
          
        }

      }catch(e){
          console.log(e);
        }
      }
      

      const info = (value) => {
        messageApi.info(value);
      };

    
        return (
          <div style={{textAlign:"center"}}>
                        <Layout>
                <Header
                    style={{
                      position: 'sticky',
                      top: 0,
                      zIndex: 1,
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      textAlign:'center',
                    }}
                  >
                    
                  <h1 style={{width:"100%",color:'white'}}>Editar Tarea</h1>
                  </Header>
                  <Content
                    style={{
                      padding: '0 48px',
                    }}
                  >
                    <Breadcrumb
                      style={{
                        margin: '16px 0',
                      }}
                    >
                      <Breadcrumb.Item>Maestro</Breadcrumb.Item>
                      <Breadcrumb.Item>Asignaturas</Breadcrumb.Item>
                      <Breadcrumb.Item>Editar Tarea</Breadcrumb.Item>
                    
                    </Breadcrumb>
                    <div
                      style={{
                      padding:"1%",
                        minHeight: 380,
                        background: colorBgContainer,
                        borderRadius: borderRadiusLG,
                        
             paddingLeft:"25%"
              
              
              
              
                      }}

                      
                    >
                      
          {contextHolder}
           {loading?"Loading": 
                <Spin spinning={isLoadingInsert}>
         
             
         <Form
           {...formItemLayout}
           style={{
           maxWidth: 600,
           }}
           form={form}
           name="submit"
           onFinish={onFinish}

         >
           <Form.Item
             name="descripcion"
             label="Descripcion"
             initialValue={activeTarea.descripcion}
             rules={[
               {
                 required: true,
                 message: 'Ingrese descripcion de tarea',
                 
               },
             ]}
           >
             <Input />
           </Form.Item >

           <Form.Item name="cierreTarea" label="Fecha de Cierre"
           initialValue={date}
           rules={[
               {
                 required: true,
                 message: 'Seleccione un dia',
               },
               
             ]}>
           <DatePicker locale={locale} onChange={onChange} />
             </Form.Item>
           <Form.Item
             name="valor"
             label="Valor"
             initialValue={activeTarea.valor} 
             rules={[
               {
                 required: true,
                 message: 'Ingrese un valor',
               },
             ]}
           >
             <InputNumber

               style={{
                 width: '100%',
               }}
             />
           </Form.Item>

         
           <Form.Item
             name="tipo"
             label="Tipo"
             initialValue={activeTarea.tipo} 
             rules={[
               {
                 required: true,
                 message: 'Seleccione un tipo!',
               },
             ]}
               >
             <Select placeholder="Seleccion el tipo de tarea">
               <Option value="Actividades-Aula">Actividades-Aula</Option>
               <Option value="Actividades-Extra-Aula">Actividades-Extra-Aula</Option>
               <Option value="Valores y actitudes">Valores y actitudes</Option>
               <Option value="Expresiones ADH">Expresiones ADH</Option>
               <Option value="Evaluaciones">Evaluaciones</Option>

             </Select>
           
           </Form.Item>       
           <Form.Item  {...tailFormItemLayout}>  
           <Button type="primary" htmlType="submit">
             Actualizar
           </Button>
           
         </Form.Item>
               
       </Form>
       </Spin>
            }
                    
                    </div>
                  </Content>
                  
                </Layout>
            </div>
          
        );

      

}

export default TareasEdit;
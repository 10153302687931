import React, { Component } from 'react';
import axios from 'axios';
import '../css/Historialacademico.css';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class Historialacademico  extends Component {
  static displayName = Historialacademico.name;

  constructor(props) {
    super(props);
    this.state = { historial: [], loading: true };
  }

  componentDidMount() {
    this.HistorialData();

  }

  static renderDatos(historial) {
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Asignatura</th>
            <th>Nota 1</th> 
            <th>Nota 2</th>
            <th>Nota 3</th> 
            <th>Nota 4</th>
            <th>Promedio</th> 
          </tr>
        </thead>
        <tbody>
          {historial.map(registro =>
            <tr key={registro.codasignatura}>
               <td>{registro.asignatura}</td> 
              <td>{registro.nt1}</td> 
              <td>{registro.nt2}</td> 
              <td>{registro.nt3}</td> 
              <td>{registro.nt4}</td>    
              <td>{registro.promedio}</td> 
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : Historialacademico.renderDatos(this.state.historial);

    return (
      <div>
        <h1 id="tabelLabel" ><center>Historial Académico</center></h1> 
        {contents}
      </div>
    );
  }

  async HistorialData() { 
    if (cookies.get('id') &&  cookies.get('token')) {
      const m_id = cookies.get('id');
      const serverApi = cookies.get('server');
      const Token = cookies.get('token'); 
      const config = {headers: { Authorization: `Bearer ${Token}` }};
  
//////

        const data = await axios.post(serverApi+'BoletaCalificaciones', {
          id: m_id
          }, config
        )  
        .then(response=>{
          
            return response.data;
        })
         
        .catch(error=>{
            console.log(error);
        })
//////

      //const data = await response.json();
      this.setState({ historial: data, loading: false });
    }
  }
}

export default Historialacademico;


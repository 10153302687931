import React, { Component } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import '../css/disciplinario.css';

const cookies = new Cookies();

class HistorialDisciplinario extends Component {
  static displayName = HistorialDisciplinario.name;

  constructor(props) {
    super(props);
    this.state = { historial: [], loading: true };
  }

  componentDidMount() {
    this.HistorialData();
  }

  render() {
    const { historial, loading } = this.state;

    let contents = loading
      ? <p><em>Loading...</em></p>
      : (
        <div className="containerr">
          <h1>Historial Disciplinario</h1>
          <div className="tablee">
            <div className="headerr">
              <div>Año</div>
              <div>Tipo falta</div>
              <div>Fecha</div>
              <div>Aplicado por</div>
            </div>
            {historial.map(registro =>
  <div key={registro.descripcion} className="roww">
    <div>{registro.codperA}</div>
    <div>{registro.tipo}</div>
    <div>{registro.fecha}</div>
    <div>{registro.aplicadopor}</div>
    <div className="description" colSpan="4">
      <table>
        <tbody>
          <tr>
            <td className="bold">Descripción:</td>
            <td>{registro.descripcion}</td>
          </tr>
          <tr>
            <td className="bold">Asunto:</td>
            <td>{registro.asunto}</td>
          </tr>
          <tr>
            <td className="bold">Aplicación:</td>
            <td>{registro.aplicacion}</td>
          </tr>
          <tr>
            <td className="bold">Observación:</td>
            <td>{registro.observacion}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
)}

          </div>
        </div>
      );

    return (
      <div>
        {contents}
      </div>
    );
  }

  async HistorialData() {
    if (cookies.get('id')) {
      const m_id = cookies.get('id');
   
      const serverApi = cookies.get('server');
      const Token = cookies.get('token');
      const config = { headers: { Authorization: `Bearer ${Token}` } };
      try {
        const response = await axios.post(serverApi + 'historialdisciplinario', {
          id: m_id
         
        }, config);
        this.setState({ historial: response.data, loading: false });
      } catch (error) {
        console.error(error);
        this.setState({ historial: [], loading: false });
      }
    }
  }
}

export default HistorialDisciplinario;

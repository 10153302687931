import {React,useEffect, useState} from 'react';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import { MailOutlined, AppstoreOutlined } from '@ant-design/icons';
import Cookies from 'universal-cookie';
import axios from 'axios';

const cookies = new Cookies();
const { Header, Content, Footer } = Layout;
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const MaestoMenu= () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [asignaturasData,setasignaturasData] = useState([]);
    const [loading,setLoading] = useState(true);
    const [codperA,setcodperA]=useState('2023');
    const [error,seterror] = useState(null);
    const [nombre,setnombre]=useState("");

    useEffect(()=>{
        if (!cookies.get('idprofesor')) {
            // Redirigir si no hay un id de profesor en las cookies
            this.props.history.push('/');
          }else{
           cargarAsignaturas();
            setnombre(cookies.get('nombre'));
          }

    },[]);

    const handleRowClick = (codasignatura,codseccion,desc) => {
        
        cookies.set('codAsignatura', codasignatura, { path: "/asignaturastarea" });
        cookies.set('codSeccion', codseccion, { path: "/asignaturastarea" });
        cookies.set('desc', desc, { path: "/asignaturastarea" });
      window.location.href='/asignaturastarea';
      };

      const cargarAsignaturas = async () => {
        
        const Idprofesor = cookies.get('idprofesor');
        const token = cookies.get('token');
        const serverApi = cookies.get('server');
    
        const config = { headers: { Authorization: `Bearer ${token}` } };
        try {
          const response = await axios.post(serverApi + "AsignaturasPorMestro", { codperA, Idprofesor }, config);
          setasignaturasData(response.data);
          setLoading(false);
          
        } catch (error) {
          console.error(error);
          setLoading(false);
          setasignaturasData([]);
          
        }
      };
  return (
    <Layout>
      <Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >

        <div className="demo-logo" />
        <Menu
          theme="dark"
          mode="horizontal"
         onClick={(key)=>{
            if(key.key==1){
                window.location.href= '/cambiarclave'; 
            }
            if(key.key==2){
                cookies.remove('id', {path: "/"}); 
                cookies.remove('idencargado', {path: "/"}); 
                cookies.remove('idprofesor', {path: "/"}); 
                window.location.href='./';
            }
         }}
          
          style={{
            flex: 1,
            minWidth: 0,
          }}
        >

      <Menu.Item key="0" >
        {nombre}
      </Menu.Item>
      <Menu.Item key="1" icon={<MailOutlined />}>
        Cambiar Clave
      </Menu.Item>
      <Menu.Item key="2" icon={<AppstoreOutlined />}>
        Cerrar Sesion
      </Menu.Item>
    </Menu>

    
      </Header>
      <Content
        style={{
          padding: '0 48px',
        }}
      >
        <Breadcrumb
          style={{
            margin: '16px 0',
          }}
        >
          <Breadcrumb.Item>Maestro</Breadcrumb.Item>
          <Breadcrumb.Item>Asignaturas</Breadcrumb.Item>
         
        </Breadcrumb>
        <div
          style={{
           
            minHeight: 380,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
            {error && <p>Error: {error.message}</p>}
            {loading ? <p><em>Loading...</em></p> : <table className='table'>
            <thead>
              <tr>
               
                <th>Asignatura</th>
                <th>Jornada</th>
                <th>Modalidad</th>
                <th>Grado</th>
                <th>Grupo</th>
                
              </tr>
            </thead>
            <tbody>
              {asignaturasData.map((asignatura, index) =>
                <tr key={index} onClick={() => handleRowClick(asignatura.codasignatura,asignatura.codseccion,asignatura.asignatura)}>
                
                  <td>{asignatura.asignatura}</td>
                  <td>{asignatura.jornada}</td>
                  <td>{asignatura.modalidad}</td>
                  <td>{asignatura.grado}</td>
                  <td>{asignatura.grupo}</td>
                  
                </tr>
              )}
            </tbody>
          </table>}
        </div>
      </Content>
      
    </Layout>
  );
};
export default MaestoMenu;
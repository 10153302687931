import React, { Component } from 'react';
import '../css/tarea.css';  
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class Tarea extends Component {
  state = {
    parcial: '',
    descripcion: '',
    est: '',
    _fechaEntrega: '',
    _fechaCierre: '',
    _tipo: '',
    _valor: ''
  }

  handleChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value
    });
  }

  formatDate = (date) => {
    if (date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
    return null;
  }

  registrarTarea = async () => {
    const token = cookies.get('token');
    const { parcial, descripcion, est, _fechaEntrega, _fechaCierre, _tipo, _valor } = this.state;
    const serverApi = cookies.get('server');

    try {
      const codasignatura = cookies.get('codasignatura');
      const codseccion = cookies.get('codseccion');
      
      const response = await axios.post(
        serverApi+"RegistrarTarea",
        {
          CodA:25,
          CodS: 3,
          parcial: parseInt(parcial),
          descripcion: descripcion,
          est: est === 'true',
          _fechaEntrega: this.formatDate(new Date(_fechaEntrega)),
          _fechaCierre: this.formatDate(new Date(_fechaCierre)),
          _tipo: _tipo,
          _valor: parseFloat(_valor),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      console.log('Fecha de Entrega:', _fechaEntrega);
      console.log('Fecha de Cierre:', _fechaCierre);
      console.log('Respuesta:', response.data);
      
      alert('¡Tarea registrada correctamente!');
  
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      
      alert('Error al registrar la tarea. Por favor, inténtelo nuevamente.');
    }
  };

  componentDidMount() {
    if (cookies.get('username')) {
      window.location.href = "./menu";
    }
  }

  render() {
    const { parcial, descripcion, _fechaEntrega, _fechaCierre, est, _tipo, _valor } = this.state;

    return (
      <div className="containerPrincipal1">
        <div className="containerSecundario">
          <div className="form-group">
            <div className="row">
              <div className="col-md-12">
                <label>Parcial:</label>
                <select
                  className="form-control2"
                  name="parcial"
                  value={parcial}
                  onChange={this.handleChange}
                >
                  <option value="1"> 1</option>
                  <option value="2"> 2</option>
                  <option value="3"> 3</option>
                  <option value="4"> 4</option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <label>Descripción:</label>
                <textarea
                  className="form-control2"
                  name="descripcion"
                  value={descripcion}
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <label>Fecha de Entrega:</label>
                <input
                  type="date"
                  className="form-control2"
                  name="_fechaEntrega"
                  value={_fechaEntrega}
                  onChange={this.handleChange}
                  placeholder="YYYY-MM-DD"
                />
              </div>
              <div className="col-md-6">
                <label>Fecha de Cierre:</label>
                <input
                  type="date"
                  className="form-control2"
                  name="_fechaCierre"
                  value={_fechaCierre}
                  onChange={this.handleChange}
                  placeholder="YYYY-MM-DD"
                />
              </div>
            </div>
            <div className="col-md-6">
              <label>Estado:</label>
              <input
                type="text"
                className="form-control2"
                name="est"
                value={est}
                onChange={this.handleChange}
              />
            </div>
            <div className="row">
              <div className="col-md-6">
                <label>Tipo:</label>
                <select
                  className="form-control2"
                  name="_tipo"
                  value={_tipo}
                  onChange={this.handleChange}
                >
                  <option value="Tipo1">Proyecto</option>
                  <option value="Tipo2">Presentacion</option>
                  <option value="Tipo3">Taller/Laboratorio</option>
                </select>
              </div>
              <div className="col-md-6">
                <label>Valor:</label>
                <input
                  type="text"
                  className="form-control2"
                  name="_valor"
                  value={_valor}
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <button className="btn btn-primary" onClick={this.registrarTarea}>
                  Registrar Tarea
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Tarea;

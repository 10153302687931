import React, { Component,setState, useEffect, useState } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom'; 
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const AsignaturasMaestroFunc = (props)=>{

    const [asignaturasData,setasignaturasData] = useState([]);
    const [loading,setLoading] = useState(true);
    const [codperA,setcodperA]=useState('2023');
    const [error,seterror] = useState(null)

    useEffect(()=>{
        if (!cookies.get('idprofesor')) {
            // Redirigir si no hay un id de profesor en las cookies
            this.props.history.push('/');
          }else{
           cargarAsignaturas();
          }

    },[]);


   
      const handleRowClick = (codasignatura,codseccion) => {
        
        cookies.set('codAsignatura', codasignatura, { path: "/asignaturastarea" });
        cookies.set('codSeccion', codseccion, { path: "/asignaturastarea" });
      window.location.href='/asignaturastarea';
      };

      const cargarAsignaturas = async () => {
        
        const Idprofesor = cookies.get('idprofesor');
        const token = cookies.get('token');
        const serverApi = cookies.get('server');
    
        const config = { headers: { Authorization: `Bearer ${token}` } };
        try {
          const response = await axios.post(serverApi + "AsignaturasPorMestro", { codperA, Idprofesor }, config);
          setasignaturasData(response.data);
          setLoading(false);
          
        } catch (error) {
          console.error(error);
          setLoading(false);
          setasignaturasData([]);
          
        }
      };

    
        return (
          <div className="table-container">
            <h1><center>Asignaturas</center></h1>
            
            {error && <p>Error: {error.message}</p>}
            {loading ? <p><em>Loading...</em></p> : <table className='table'>
            <thead>
              <tr>
               
                <th>Asignatura</th>
                <th>Jornada</th>
                <th>Modalidad</th>
                <th>Grado</th>
                <th>Grupo</th>
                
              </tr>
            </thead>
            <tbody>
              {asignaturasData.map((asignatura, index) =>
                <tr key={index} onClick={() => handleRowClick(asignatura.codasignatura,asignatura.codseccion)}>
                
                  <td>{asignatura.asignatura}</td>
                  <td>{asignatura.jornada}</td>
                  <td>{asignatura.modalidad}</td>
                  <td>{asignatura.grado}</td>
                  <td>{asignatura.grupo}</td>
                  
                </tr>
              )}
            </tbody>
          </table>}
          </div>
        );

      

}

export default AsignaturasMaestroFunc;
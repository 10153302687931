import React, { Component } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class Horario extends Component {
  static displayName = Horario.name;

  constructor(props) {
    super(props);
    this.state = { historial: [], loading: true, selectedDay: '' };
  }

  componentDidMount() {
    this.HistorialData();
  }

  static renderDatos(historial, selectedDay) {
    let datosFiltrados = historial;

    if (selectedDay !== '') {
      datosFiltrados = historial.filter(registro => registro.dia === selectedDay);
    }

    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Hora</th>
            <th>Asignatura</th>
            <th>Profesor</th>
            <th>Aula</th>
            <th>Dia</th>
            <th>Semestre</th>
          </tr>
        </thead>
        <tbody>
          {datosFiltrados.map(registro =>
            <tr key={registro.descripcion}>
              <td>{registro.hora}</td>
              <td>{registro.asignatura}</td>
              <td>{registro.profesor}</td>
              <td>{registro.aula}</td>
              <td>{registro.dia}</td>
              <td>{registro.semestre}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  render() {
    const { historial, loading, selectedDay } = this.state;

    let contents = loading
      ? <p><em>Loading...</em></p>
      : (
        <div>
          <select value={selectedDay} onChange={this.handleDayChange}>
            <option value="">Seleccionar día</option>
            <option value="Lunes">Lunes</option>
            <option value="Martes">Martes</option>
            <option value="Miercoles">Miércoles</option>
            <option value="Jueves">Jueves</option>
            <option value="Viernes">Viernes</option>
            <option value="Sábado">Sábado</option>
            <option value="Domingo">Domingo</option>
          </select>
          {Horario.renderDatos(historial, selectedDay)}
        </div>
      );

    return (
      <div>
        <h1 id="tabelLabel"><center>Horario</center></h1>
        {contents}
      </div>
    );
  }

  handleDayChange = event => {
    this.setState({ selectedDay: event.target.value });
  };

  async HistorialData() {
    if (cookies.get('id')) {
      const m_id = cookies.get('id');
      
      const Token = cookies.get('token');
      const config = { headers: { Authorization: `Bearer ${Token}` } };
      try {
        const response = await axios.post('https://castinnovation.somee.com/api/horarioporid', {
          id: m_id
          
        }, config);
        this.setState({ historial: response.data, loading: false });
      } catch (error) {
        console.error(error);
        this.setState({ historial: [], loading: false });
      }
    }
  }
}

export default Horario;

import * as React from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import SchoolIcon from '@mui/icons-material/School';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import Cookies from 'universal-cookie';
const { Header, Content, Footer } = Layout;
const cookies = new Cookies;

export default function MenuAlumno() {
    
    const cerrarSesion = () => {
        cookies.remove('id', { path: "/" });
        cookies.remove('idencargado', { path: "/" });
        window.location.href = './';
    };

   

    const mostrarHAcademico = () => {
        window.location.href = '/historialacademico'; // Usa history.push para navegar
    };

    const mostrarHEconomico = () => {
        window.location.href = '/historialeconomico';
    };

    const mostrarHDisciplinario = () => {
        window.location.href = '/historialdisciplinario';
    };

    const mostrarHOrario = () => {
        window.location.href = '/horario';
    };

    const cambiarClave = () => {
        window.location.href = '/cambiarclave';
    };

    const obtenertareaalumno = () => {
        window.location.href = '/obtenertareaalumno';
    };

    const mostrarAsignaturasAlumno = () => {
        window.location.href = '/asignaturaalumno';
    };
  return (
    <div style={{textAlign:"center"}}>
    <Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          textAlign:'center',
        }}
      >
        
       <h1 style={{width:"100%",color:'white'}}>Menu Encargado</h1>
      </Header>
    <Paper sx={{ width: 320, maxWidth: '100%', position: "absolute",
    width:"400px",
    
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "10px" }}>
      
      <MenuList>
        <MenuItem onClick={mostrarHAcademico}>
          <ListItemIcon>
            <SchoolIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Historial Academico</ListItemText>
          <Typography variant="body2" color="text.secondary">
            ⌘
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={mostrarHEconomico}>
          <ListItemIcon>
            <EscalatorWarningIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Historial Economico</ListItemText>
          <Typography variant="body2" color="text.secondary">
            ⌘
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={mostrarHDisciplinario}>
          <ListItemIcon>
            <EscalatorWarningIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Historial Disciplinario</ListItemText>
          <Typography variant="body2" color="text.secondary">
            ⌘
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={mostrarHOrario}>
          <ListItemIcon>
            <EscalatorWarningIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Horario</ListItemText>
          <Typography variant="body2" color="text.secondary">
            ⌘
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={obtenertareaalumno}>
          <ListItemIcon>
            <EscalatorWarningIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Tareas</ListItemText>
          <Typography variant="body2" color="text.secondary">
            ⌘
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={mostrarAsignaturasAlumno}>
          <ListItemIcon>
            <EscalatorWarningIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Asignaturas</ListItemText>
          <Typography variant="body2" color="text.secondary">
            ⌘
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={cerrarSesion}>
          <ListItemIcon>
            <EscalatorWarningIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>cerrar Sesion</ListItemText>
          <Typography variant="body2" color="text.secondary">
            ⌘
          </Typography>
        </MenuItem>
        </MenuList>
    </Paper>
    </div>);
}

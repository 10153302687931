import React, { Component } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class AsignaturaAlumno extends Component {
  static displayName = AsignaturaAlumno.name;

  constructor(props) {
    super(props);
    this.state = { asignaturasData: [], loading: true };
  }

  componentDidMount() {
    this.obtenerAsignaturas();
  }

  static renderDatos(asignaturasData, handleClick) {
    return (
      <table className='table'>
        <thead>
          <tr>
            <th>Código Asignatura</th>
            <th>Asignatura</th>
            <th>Categoría</th>
          </tr>
        </thead>
        <tbody>
          {asignaturasData.map(asignatura =>
            <tr key={asignatura.codasignatura} onClick={() => handleClick(asignatura.codasignatura)}>
              <td>{asignatura.codasignatura}</td>
              <td>{asignatura.nombre}</td>
              <td>{asignatura.categoria}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  render() {
    const { loading, asignaturasData } = this.state;

    return (
      <div className="table-container">
        <h1><center>Asignaturas</center></h1>
        {loading ? <p><em>Loading...</em></p> : AsignaturaAlumno.renderDatos(asignaturasData, this.handleRowClick)}
      </div>
    );
  }

  handleRowClick = (codasignatura) => {
    cookies.set('codasignatura', codasignatura, { path: '/' });
     // Redirige a la página obtenertarea
  };

  async obtenerAsignaturas() {
    if (cookies.get('id')) {
      const idalumno = cookies.get('id');
      const token = cookies.get('token');
      const serverApi = cookies.get('server');

      const config = { headers: { Authorization: `Bearer ${token}` } };
      try {
        const response = await axios.post(serverApi + "AsignaturaPorAlumno", { idalumno }, config);
        this.setState({ asignaturasData: response.data, loading: false });
      } catch (error) {
        console.error(error);
        this.setState({ asignaturasData: [], loading: false });
      }
    }
  }
}

export default AsignaturaAlumno; // Envuelve el componente con withRouter para acceder al historial y habilitar la redirección

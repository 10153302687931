import React, { Component } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class Historialeconomico  extends Component {
  static displayName = Historialeconomico.name;

  constructor(props) {
    super(props);
    this.state = { historial: [], loading: true };
  }

  componentDidMount() {
    this.HistorialData();

  }

  static renderDatos(historial) {
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Descripcion</th>
            <th>Debe</th> 
          </tr>
        </thead>
        <tbody>
          {historial.map(registro =>
            <tr key={registro.descripcion}>
              <td>{registro.descripcion}</td> 
              <td>{registro.debe}</td> 
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : Historialeconomico.renderDatos(this.state.historial);

    return (
      <div>
        <h1 id="tabelLabel" ><center>Historial económico</center></h1> 
        {contents}
      </div>
    );
  }

  async HistorialData() {
    if (cookies.get('id')) {
      const m_id = cookies.get('id');
  
      const serverApi = cookies.get('server');
      const Token = cookies.get('token'); 
      const config = {headers: { Authorization: `Bearer ${Token}` }}; 
        const data = await axios.post(serverApi+'EstadoCuenta', {
          id: m_id
        }, config
        )  
        .then(response=>{
            return response.data;
        })
         
        .catch(error=>{
            console.log(error);
        })
       
      this.setState({ historial: data, loading: false });
    }
  }
}

export default Historialeconomico;


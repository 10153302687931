import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import {React} from "react"
import Cookies from "universal-cookie";
import axios from "axios";

const cookies = new Cookies();


const  LogInView=()=> {
  // const [info, setInfo] = useState();
  const handleSubmit = async(event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const identidad =data.get("identidad");
    const contra = data.get("contraseña");
    const numeroSerie=data.get("numserie");
    

    

    if(identidad !== "" || contra !==""){
        const serverApi = "https://castinnovation.somee.com/api/";
            // Destructuring para obtener los valores de forma más clara
            await axios.post(serverApi + "accesousuarios", {
                id: identidad,
                clave: contra,
                numeroSerie: numeroSerie
            })
            .then(response => { 
                if (response.data.length > 0) { 
                    console.log(response);
                    var respuesta = response.data[0]; 
                    cookies.set('server', serverApi, { path: "/" });
                    cookies.set('nombre', respuesta.nombre, { path: "/" });

                    switch (respuesta.perfil) {
                        case "Alumno": 
                            cookies.set('id', respuesta.id, { path: "/" });
                            cookies.set('id', respuesta.id, { path: "/cambiarclave" });
                            cookies.set('token', respuesta.token, { path: "/" }); 
                            window.location.href = "./alumno"; 
                            break;
                        case "Encargado": 
                            cookies.set('idencargado', respuesta.id, { path: "/" }); 
                            cookies.set('id', respuesta.id, { path: "/cambiarclave" });
                            cookies.set('token', respuesta.token, { path: "/" }); 
                            window.location.href = "./listaencargado"; 
                            break; 
                        case "Profesor": 
                            cookies.set('idprofesor', respuesta.id, { path: "/" }); 
                            cookies.set('id', respuesta.id, { path: "/cambiarclave" });
                            cookies.set('token', respuesta.token, { path: "/" }); 
                            window.location.href = "./maestro"; 
                            break; 
                        case "Profesor;Encargado": // Maneja el caso de encargado y profesor
                            cookies.set('idencargado', respuesta.id, { path: "/" }); 
                            cookies.set('idprofesor', respuesta.id, { path: "/" }); 
                            cookies.set('id', respuesta.id, { path: "/cambiarclave" });
                            cookies.set('token', respuesta.token, { path: "/" }); 
                            // Redirige a una página específica para encargados y profesores
                            window.location.href = "./SeleccionarRol"; 
                            break;
                        default:
                            alert('El usuario no tiene un perfil válido');
                            break;
                    }
                } else {
                    alert('El usuario o la contraseña no son correctos');
                }
            })
            .catch(error => {
                console.log(error);
                alert('Error');
            })

      }else{
        alert("llena los espacios vacios");
      }


    

    
  };


  


  return (
    <Container component="main" maxWidth="lg" style={{paddingTop:"8%"}}>
      <Box
        sx={{
          marginTop: 8,
        }}
      >
        <Grid container>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: "url(https://source.unsplash.com/random)",
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
               Iniciar Sesion
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="identidad"
                  label="Identidad"
                  name="identidad"
                  autoComplete="identidad"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="contraseña"
                  label="Contraseña"
                  type="password"
                  
                  id=""
                  autoComplete="current-password"
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="numserie"
                  label="Numero Serie"
                  name="numserie"
                  autoComplete="numserie"
                  autoFocus
                />
                {/* <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                /> */}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Ingresar
                </Button>
                <Grid container>
                  <Grid item xs>
                    
                  </Grid>
                  
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
export default LogInView;
import React, { Component } from 'react';
import axios from 'axios';
import '../css/obtenertarea.css'; // Importa el archivo CSS
import Cookies from 'universal-cookie'; 
const cookies = new Cookies();

class obtenertarea extends Component {
  state = {
    tareaData: [],
    error: null
  }
  
  componentDidMount() {
    this.obtenerTarea();
  }

  obtenerTarea = async () => {
    const codasignatura = cookies.get('codasignatura');
    if (codasignatura) {
      try {
        const serverApi = cookies.get('server');
        const token = cookies.get('token');

        const response = await axios.post(serverApi + "ObtenerTarea", {
          codasignatura: parseInt(codasignatura)
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        console.log('Response:', response.data);

        this.setState({
          tareaData: response.data || [],
          error: null
        });

      } catch (error) {
        console.error('Error:', error);

        this.setState({
          tareaData: [],
          error: 'Error al obtener la tarea. Verifica los datos ingresados.'
        });
      }
    } else {
      this.setState({
        error: 'No se ha proporcionado el parámetro "codasignatura".'
      });
    }
  }

  render() {
    const { tareaData, error } = this.state;

    return (
      <div className="containerPrincipaal">
        <div className="containersecundariiio">
          <div className="form-group">
            {tareaData.length > 0 && (
              <div className="row mt-3">
                <div className="col-md-12">
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ display: 'none' }}>Código Asignatura</th>
                        <th style={{ display: 'none' }}>Código Sección</th>
                        <th>Descripción</th>
                        <th>Estado</th>
                        <th>Fecha Cierre</th>
                        <th>Fecha Entrega</th>
                        <th>Parcial</th>
                        <th>Tipo</th>
                        <th>Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tareaData.map((tarea, index) => (
                        <tr key={index}>
                          <td style={{ display: 'none' }}>{tarea.codasignatura}</td>
                          <td style={{ display: 'none' }}>{tarea.codseccion}</td>
                          <td>{tarea.descripcion}</td>
                          <td>{tarea.estado ? 'Activo' : 'Inactivo'}</td>
                          <td>{tarea.fechaCierre}</td>
                          <td>{tarea.fechaEntrega}</td>
                          <td>{tarea.parcial}</td>
                          <td>{tarea.tipo}</td>
                          <td>{tarea.valor}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {error && (
              <div className="row mt-3">
                <div className="col-md-12">
                  <p className="text-danger">{error}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default obtenertarea;

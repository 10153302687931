import React, { Component } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class LoginEncargado extends Component {
  state = {
    username: '',
    error: null,
  };

  handleChange = (e) => {
    this.setState({ username: e.target.value });
  };

  handleLogin = () => {
    const { username } = this.state;

    // Realiza una solicitud HTTP para verificar el usuario
   axios.get(`https://www.chevez.somee.com/api/EstadoCuenta/${username}`)
      .then((response) => {
        const userData = response.data;

        if (userData) {
          // Usuario encontrado, establece una cookie o usa otra lógica de autenticación
          cookies.set('id', userData.id, { path: '/' });
          // Redirige al usuario a otra página
          this.props.history.push('/alumno'); // Ajusta la ruta según tu aplicación
        } else {
          this.setState({ error: 'Usuario no encontrado' });
        }
      })
      .catch((error) => {
        this.setState({ error: 'Error en la solicitud' });
      });
  };

  render() {
    return (
      <div>
        <h1>Iniciar Sesión</h1>
        <div>
          <label>Nombre de Usuario: </label>
          <input
            type="text"
            value={this.state.username}
            onChange={this.handleChange}
          />
        </div>
        <button onClick={this.handleLogin}>Iniciar Sesión</button>
        {this.state.error && <p>{this.state.error}</p>}
      </div>
    );
  }
}

export default LoginEncargado;

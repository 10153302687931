import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import historialacademico from './historialeconomico';
import '../css/Alumno.css';
const cookies = new Cookies();

class Maestro extends Component {
    cerrarSesion=()=>{
        cookies.remove('id', {path: "/"}); 
        cookies.remove('idencargado', {path: "/"}); 
        window.location.href='./';
    }

    //componentDidMount() {
      //  if(!cookies.get('id')){
       //     window.location.href="./";
      //  }
   // }
    tarea () {

        window.location.href= '/tarea'; // Redirige a la ruta especificada
      }
      obtenertarea () {
      
        window.location.href= '/obtenertarea'; // Redirige a la ruta especificada
      }
    cambiarCLave () {

        window.location.href= '/cambiarclave'; // Redirige a la ruta especificada
    }
    actualizarvalor () {

        window.location.href= '/actualizarvalor'; // Redirige a la ruta especificada
    }
    asignaturasmaestros () {
        
        window.location.href= '/asignaturasmaestro'; // Redirige a la ruta especificada
      }

    render() {
        return (
            <div className="alumno-container">
                {/* Mueve el botón "Cambiar Clave" al principio */}
             
                <button className="button" onClick={() => this.cambiarCLave()}>Cambiar Clave</button> 
          <button className="button" onClick={() => this.tarea()}>Asignar Tarea</button> 
          <button className="button" onClick={() => this.obtenertarea()}>Obtener Tarea </button> 
          <button className="button" onClick={() => this.actualizarvalor()}>Actualizar Valor</button> 
          <button className="button" onClick={() => this.asignaturasmaestros()}>Asignaturas</button> 


    
                <button className="button logout-button" onClick={() => this.cerrarSesion()}>Cerrar Sesión</button>
            </div>
            
        );
        
    }
    
}

export default Maestro;
import React, { Component } from 'react';
import { Link } from 'react-router-dom'; // Importa Link desde react-router-dom
import Cookies from 'universal-cookie';
import '../css/listaencargado.css';
import axios from 'axios';

const cookies = new Cookies();

class ListaEncargado extends Component {
  constructor(props) {
    super(props);
    this.state = { historial: [], loading: true };
  }

  componentDidMount() {
    this.HistorialData();
  }
  mostrarAlumno (myid) {
    cookies.set('id', myid.id, {path: "/"}); 
    window.location.href= '/alumno'; // Redirige a la ruta especificada
}
cambiarCLave () {

  window.location.href= '/cambiarclave'; // Redirige a la ruta especificada
}
//tarea () {

  //window.location.href= '/tarea'; // Redirige a la ruta especificada
//}
obtenertarea () {

  window.location.href= '/obtenertarea'; // Redirige a la ruta especificada
}

  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : this.state.historial.map(registro => (
        <div key={registro.id}>
          <p>ID de Alumno: {registro.id}</p>
          <p>Nombre: {registro.nombre}</p>
          <button onClick={() => this.mostrarAlumno(registro)}>Ver detalles</button> 
          <hr />
        </div>
      ));
       <button className="button" onClick={() => this.cambiarCLave()}>Cambiar Clave</button> 
    return (
      <div className="lista-encargado-container">
        <h1>Lista de Alumno</h1>
        <div className="lista-alumnos">
            {contents}
          </div>
          <button className="button" onClick={() => this.cambiarCLave()}>Cambiar Clave</button> 
      </div>
    );
  }

  async HistorialData() {
    
    if (cookies.get('idencargado')) { 
      const idencargado = cookies.get('idencargado');
      const Id = cookies.get('Id');
      const id = cookies.get('id');
      const idprofesor = cookies.get('idprofesor');
      const Token = cookies.get('token'); 
      const config = {headers: { Authorization: `Bearer ${Token}` }}; 

        const data = await axios.post('https://castinnovation.somee.com/api/AlumnoEncargado', {
          id: idencargado,
          idprofesor: idprofesor,
          Id: Id,
          myid: id}, config
        )  
        .then(response=>{
            return response.data;
        })
         
        .catch(error=>{
            console.log(error);
        })
      this.setState({ historial: data, loading: false });
    }
  }
}

export default ListaEncargado;

